import * as z from "zod";
import { parseWithSchema } from "../../../Infrastructure/Services/ValidationUtil";
import { useBaseQuery } from "../../../Infrastructure/queryClient/useQuery";
import { useQueryClient } from "@tanstack/react-query";

const createUrl = (patientId: string) =>
    `FeedbackTest/GetFeedbackTestStates?patientId=${patientId}`;

const boundedContext = "FeedbackTest";

const useFeedbackTestStateQuery = (patientId: string) => {
    return useBaseQuery<FeedbackTestState>(
        {
            url: createUrl(patientId),
            boundedContext,
            notifyOnChangeOn: patientId,
            validate: result => parseWithSchema(feedbackTestStatedSchema, result),
        },
        { enabled: !!patientId }
    );
};

const useSetLocalFeedbackTestState = () => {
    const queryClient = useQueryClient();
    return (
        hearingAidId: string,
        patientId: string,
        dismissibleError: FeedbackTestDismissibleError,
        error: FeedbackTestError | null,
        state?: CalibrationState
    ) => {
        const queryKey = [boundedContext, patientId, createUrl(patientId)];
        const queryData = queryClient.getQueryData<FeedbackTestState>(queryKey);
        const hearingAid = queryData?.find(x => x.hearingAidId === hearingAidId);

        if (!queryData || !hearingAid) return;

        queryClient.setQueryData<FeedbackTestState | undefined>(
            queryKey,
            queryData.map(ha =>
                ha.hearingAidId === hearingAidId
                    ? { ...ha, dismissibleError, error, state: state ?? ha.state }
                    : ha
            )
        );
    };
};

const calibrationStateSchema = z.enum(["notReady", "notCalibrated", "inProgress", "calibrated"]);

const feedbackTestDismissibleErrorSchema = z.enum([
    "none",
    "tooMuchNoise",
    "eepromWriteFailed",
    "overflow",
    "undefined",
    "generalBackendError",
    "pollingExpired",
]);

const feedbackTestErrorSchema = z.nullable(z.enum(["none", "masterProgramRequired"]));

const hearingAidStateSchema = z.object({
    hearingAidId: z.string(),
    state: calibrationStateSchema,
    dismissibleError: feedbackTestDismissibleErrorSchema,
    error: feedbackTestErrorSchema,
});
const feedbackTestStatedSchema = z.array(hearingAidStateSchema);

export type FeedbackTestState = z.infer<typeof feedbackTestStatedSchema>;
export type CalibrationState = z.infer<typeof calibrationStateSchema>;
export type FeedbackTestDismissibleError = z.infer<typeof feedbackTestDismissibleErrorSchema>;
export type FeedbackTestError = z.infer<typeof feedbackTestErrorSchema>;
export { useFeedbackTestStateQuery, useSetLocalFeedbackTestState };
