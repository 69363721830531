/* eslint-disable indent */
import { generatePath } from "react-router-dom";

export const transferNoahPatientPath = "transfernoahpatient";
export const loggedoutPath = "loggedout";
export const publicPath = "public";
export const settingsPath = "settings";
export const userGuidePath = "user-guide";
export const pageUnderConstructionPath = ":subPath1/:subPath2";
export const patientsPath = "patients";
export const patientIdTemplate = ":patientId";
const patientsListPath = "/" + patientsPath;
export const patientIdAbsolutePathTemplate = "/" + patientsPath + "/" + patientIdTemplate;
const deviceAssignmentPathTemplate = "patients/:patientId/devices";
const basicFittingPathTemplate = "patients/:patientId/basic-fitting";
export const acousticSelectionPathTemplate = "acoustic-selection";
const acousticSelectionAbsolutePathTemplate =
    patientIdAbsolutePathTemplate + "/" + acousticSelectionPathTemplate;
export const programsAndTuningPathTemplate = "programs-and-tuning";
export const firmwareUpdateTemplate = "firmware-update";
const firmwareUpdateAbsolutePathTemplate =
    patientIdAbsolutePathTemplate + "/" + firmwareUpdateTemplate;
export const feedbackTestPath = "acoustic-calibration";
export const deviceSettingsPathTemplate = "device-settings";
export const clientPrivacyPath = "client-privacy";
export const thirdPartyLicensePath = "third-party-licenses";
export const fineTuningPathTemplate = "finetuning";
export const fineTuningAbsolutePathTemplate =
    patientIdAbsolutePathTemplate + "/" + fineTuningPathTemplate;
export const sensogramPath = "sensogram";
export const testModePath = "tools/test-mode";
export const fittingReportPath = "fitting-report";
export const deviceSettingsReportPath = "device-settings-report";
export const activityLogPath = "activity-log";
export const curvesDumpPath = "curves-dump";

export const getFittingReportPath = (patientId: string | undefined, language: string) =>
    !patientId
        ? `${fittingReportPath}?lang=${language}`
        : `${patientsPath}/${patientId}/${fittingReportPath}?lang=${language}`;

export const getDeviceSettingsPath = (patientId: string | undefined, language: string) =>
    !patientId
        ? `${deviceSettingsReportPath}?lang=${language}`
        : `${patientsPath}/${patientId}/${deviceSettingsReportPath}?lang=${language}`;
export const getTestModeUrl = (patientId: string | undefined) =>
    !patientId ? `${testModePath}` : `${patientsPath}/${patientId}/${testModePath}`;

export const getSettingsUrl = (patientId: string | undefined) =>
    !patientId ? `${settingsPath}` : `${patientsPath}/${patientId}/${settingsPath}`;

export const getUserGuideUrl = (patientId: string | undefined) =>
    !patientId ? `${userGuidePath}` : `${patientsPath}/${patientId}/${userGuidePath}`;

export const getPatientsUrl = () => patientsPath;

export const getPatientsListUrl = () => patientsListPath;

export const getAbsolutePatientsUrl = () => "/" + patientsPath;

export const getActivityLogPath = () => "/" + activityLogPath;

export const getPatientUrl = (patientId: string) =>
    generatePath(patientIdAbsolutePathTemplate, { patientId });

export const getAbsolutePatientUrl = (patientId: string) =>
    generatePath(patientIdAbsolutePathTemplate, { patientId });

export const getDeviceAssignmentUrl = (patientId: string) =>
    generatePath(deviceAssignmentPathTemplate, { patientId });

export const getBasicFittingUrl = (patientId: string) =>
    generatePath(basicFittingPathTemplate, { patientId });

export const getAcousticSelectionUrl = (patientId: string) =>
    generatePath(acousticSelectionAbsolutePathTemplate, { patientId });

export const getFirmwareUpdateUrl = (patientId: string) =>
    generatePath(firmwareUpdateAbsolutePathTemplate, { patientId });

export const getFeedbackTestUrl = (patientId: string) =>
    generatePath(feedbackTestPath, { patientId });

export const getSensogramUrl = (patientId: string) => generatePath(sensogramPath, { patientId });

export const getProgramsAndTuningUrl = (patientId: string) =>
    generatePath(programsAndTuningPathTemplate, { patientId });

export const getDeviceSettingsUrl = (patientId: string) =>
    generatePath(deviceSettingsPathTemplate, { patientId });

export const getCurvesDumpUrl = (patientId: string) => generatePath(curvesDumpPath, { patientId });
